.wrapper {
  --cui-sidebar-width: 16rem;
  width: calc(100% - var(--cui-sidebar-width)) !important;
  margin-left: var(--cui-sidebar-width) !important;
}


.sidebar .sidebar-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mob-bars {
  display: none;
}


.f-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.mr-3 {
  margin-right: 15px;
}

@media screen and (max-width: 1023.99px) {
  .sidebar-toggler {
    display: inline-block !important;
  }
  .wrapper {
    --cui-sidebar-width: 0;
    width: 100% !important;
    margin-left: 0;
  }

  .sidebar.active {
    margin-left: 0 !important;
  }

  /* .d-flex .form-check {
    display: block;
  } */

  .d-flex {
    flex-direction: column;
  }

  .d-flex.justify-content-start {
    display: flex;
  }

  .row {
    display: block;
  }

  .col {
    width: 100%;
  }

  .mob-bars {
    display: inline-block;
  }

}
